<template>
  <div style="padding:10px;">
    <!-- 功能区域 -->
    <div style="margin: 10px 0">
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <!-- 搜索区域 -->
    <div style="margin: 10px 0">
      <el-input v-model="search" placeholder="请输入信息单号/备注" style="width: 20%" clearable/>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
    </div>

    <!-- 表格 -->
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="id" label="ID" sortable width="150"/>
      <el-table-column prop="name" label="信息单号"/>
      <el-table-column prop="remark" label="备注说明"/>
      <el-table-column prop="create_time" label="操作时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-popconfirm title="确认删除吗?" @confirm="handleDelete(scope.row.id)">
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
          <el-button size="mini" type="primary" @click="handleNews(scope.row)">添加轨迹</el-button>
          <el-button size="mini" type="primary" @click="handleDetailNews(scope.row)">查看轨迹</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div style="margin: 10px 0">
      <el-pagination
          v-model:currentPage="currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      >
      </el-pagination>

      <!-- 新增 弹窗提示 -->
      <el-dialog title="提示" v-model="dialogVisible" width="30%">
        <el-form :model="form" label-width="120px" :rules="rules">
          <el-form-item label="信息单号" prop="name">
            <el-input v-model="form.name" style="width:80%"></el-input>
          </el-form-item>
        </el-form>
        <el-form :model="form" label-width="120px">
          <el-form-item label="备注说明" prop="remark">
            <el-input v-model="form.remark" style="width:80%"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="save">确 定</el-button>
                    </span>
        </template>
      </el-dialog>

      <!-- 新增 轨迹弹窗提示 -->
      <el-dialog title="提示" v-model="dialogVisibleNews" width="50%">
        <el-form :model="newsForm" label-width="120px" :rules="rulesNews">
          <el-form-item label="信息单号" prop="bookid">
            <el-select v-model="newsForm.bookid" filterable placeholder="请选择信息单号" style="width:50%">
              <el-option v-for="task in bookList"
                         :key="task.id"
                         :label="task.name"
                         :value="task.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="标题" prop="title">
            <el-input v-model="newsForm.title" style="width:50%"></el-input>
          </el-form-item>
          <div id="div1"></div>
          <el-form-item label="内容" prop="content">
            <el-input v-model="newsForm.content" style="width:80%"></el-input>
          </el-form-item>
          <el-form-item label="发生时间" prop="time">
            <el-date-picker v-model="newsForm.time" value-format="YYYY-MM-DD HH:mm:ss"
                            format="YYYY-MM-DD HH:mm:ss" type="datetime" style="width:50%" clearable></el-date-picker>
          </el-form-item>
        </el-form>
        <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisibleNews = false">取 消</el-button>
                        <el-button type="primary" @click="saveNews">确 定</el-button>
                    </span>
        </template>
      </el-dialog>

      <!-- 查看物流进度的对话框-->
      <el-dialog title="信息进度" v-model="dialogVisibleNewsDetail" width="50%">
        <div class="track-rcol">
          <div class="track-list">
            <ul>
              <div v-for="(item, index) in progressInfo" :key="index">
                <div>
                  <li>
                    <i :class="{
                                'steps-line': index < progressInfo.length - 1,
                                'steps-no-line': index == progressInfo.length - 1,
                                }"></i>
                    <div>
                      <i class="node-icon icon"></i>
                      <span class="title">{{item.news_title}}</span><span>{{ item.news_formatTime }}</span>
                    </div>
                    <div><span class="name">{{item.news_content}}</span></div>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleNewsDetail = false">取 消</el-button>
          </span>
        </template>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import request from "../utils/request";

export default {
  name: 'Book',
  components: {},
  data() {
    return {
      form: {},
      newsForm: {},
      bookList: [],
      dialogVisible: false,
      dialogVisibleNews: false,
      dialogVisibleNewsDetail: false,
      search: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      user: {},
      vis: false,
      ids: [],
      dataId: 0,
      progressInfo: [],
      codeInfo:'',
      rules: {
        name: [{required: true, message: '请输入单号', trigger: 'blur',}],
      },
      rulesNews: {
        bookid: [{required: true, message: '请选择信息单号', trigger: 'blur',}],
        title: [{required: true, message: '请输入标题', trigger: 'blur',}],
        content: [{required: true, message: '请输入内容', trigger: 'blur',}],
        time: [{required: true, message: '请选择时间', trigger: 'blur',}],
      }
    }
  },
  created() {
    let userStr = sessionStorage.getItem("user") || "{}"
    this.user = JSON.parse(userStr);
    if (this.user.id) {
      //请求服务端 确认当前登录用户的合法信息
      request.post("/user/detail.php", {
        dataId: this.user.id,
      }).then(res => {
        if (res.code === '0') {
          this.user = res.data
        }
      })
      this.load()
    }

  },
  methods: {
    load() {
      request.post("/book/list.php", {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        search: this.search
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    add() {
      this.dialogVisible = true
      this.form = {}
    },
    handleNews(row) {
      this.bookList = [];
      this.newsForm = {};
      let tempForm = JSON.parse(JSON.stringify(row));
      this.bookList.push(tempForm);
      this.newsForm.bookid = tempForm.id;
      this.dialogVisibleNews = true
    },
    save() {
      if (!this.form.name) {
        this.$message({
          type: "error",
          message: '请输入信息单号！'
        })
        return;
      }
      if (this.form.id) { //判断是否拥有用户ID 有则更新
        request.post("/book/update.php", this.form).then(res => {
          if (res.code == '0') {
            this.$message({
              type: "success",
              message: "更新成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load() //刷新表格数据
          this.dialogVisible = false //关闭弹窗
        })
      } else { //新增
        request.post("/book/save.php", this.form).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "新增成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load() //刷新表格数据
          this.dialogVisible = false //关闭弹窗
        })
      }
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    handleDelete(id) {
      request.post("/book/delete.php", {
        dataId: id,
      }).then(res => {
        if (res.code === '0') {
          this.$message({
            type: "success",
            message: "删除成功"
          })
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
        this.load() //重新加载数据
      })
    },
    handleSizeChange(pageSize) { //改变当前每页的个数触发
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) { //改变当前页码触发
      this.currentPage = pageNum
      this.load()
    },
    saveNews() {

      if (!this.newsForm.bookid) {
        this.$message({
          type: "error",
          message: '请选择信息单号！'
        })
        return;
      }
      if (!this.newsForm.title) {
        this.$message({
          type: "error",
          message: '请输入标题！'
        })
        return;
      }
      if (!this.newsForm.content) {
        this.$message({
          type: "error",
          message: '请输入内容！'
        })
        return;
      }
      if (!this.newsForm.time) {
        this.$message({
          type: "error",
          message: '请选择时间！'
        })
        return;
      }
      let userStr = sessionStorage.getItem("user") || "{}"
      let user = JSON.parse(userStr)
      this.newsForm.author = user.nickName
      request.post("/news/save.php", this.newsForm).then(res => {
        if (res.code === '0') {
          this.$message({
            type: "success",
            message: "新增成功"
          })
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
        this.load() //刷新表格数据
        this.dialogVisibleNews = false //关闭弹窗
      })
    },
    handleDetailNews(row) {
      this.progressInfo=[];
      this.codeInfo = '';
      let tempForm = JSON.parse(JSON.stringify(row));
      request.post("/book/code.php", {
        code: tempForm.name,
      }).then(res => {
        console.log(res);
        if (res.code === '0') {
          this.progressInfo = res.data;
          this.codeInfo  = tempForm.name;
        }
      })
      this.dialogVisibleNewsDetail = true


    }
  }
}
</script>
<style>
ul li {
  list-style: none;
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem;
}


.track-list ul li {
  position: relative;
  padding: 0px 0px 25px 45px;
}

.track-list .node-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 4px;
}


.track-list .icon {
  width: 20px;
  height: 20px;
  background-color: #efefef;
  border-radius: 20px;
}

.track-list li {
  margin-top: 10px;
}

.track-list li span {
  position: relative;
  top: 4px;
  margin-right: 8px;
  font-size: 12px;
}

.track-list li .title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}


.track-list li .steps-line {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 0px;
  height: 100%;
  border-right: 1px solid #999;
}
</style>
